.page {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}
.page__footer {
  padding: 20px;
}
#game {
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  background: #0062B9;
}
.bowl-info {
  display: flex;
  justify-content: space-around;
}
.wallet {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
