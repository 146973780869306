.page {
  background-color: #f0f0f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
}

.page__footer {
  padding: 20px;
}

#game {
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  background: #0062b9;
}

.bowl-info {
  justify-content: space-around;
  display: flex;
}

.wallet {
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  display: inline-flex;
}
/*# sourceMappingURL=index.a02cbb75.css.map */
